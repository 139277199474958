import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const PermStatusState = {
    GRANTED: 'granted',
    DENIED: 'denied',
    PROMPT: 'prompt'
};


const useGeolocation = (options = {}) => {
    const config = useMemo(() => ({
        enableHighAccuracy: true,
        timeout: 6000,
        maximumAge: 0,
        ...options
    }), [options]);

    const getPosition = useCallback(() => new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, config);
    }), [config]);

    const getPermission = useCallback(() => (
        navigator.permissions.query({ name: 'geolocation' })
    ), []);

    return { getPermission, getPosition };
};

export { useGeolocation, PermStatusState };
