// import url from 'url';
import SearchIntendTypes from '@/components/map/enums/SearchIntendTypes';
import currencyRateConverter from '@/src/utils/currencyRateConverter';
import { DestinationTypeEnum } from '@/static/contants';

import { recommendedWiredKeys } from './DropDownCollection/DropDownCmps/Recommended/useRecommended';
import { LocationTypes, resolutionType, viewState } from './enum';
import { getCountByGuestType, guestAgeTypes } from '@/components/formComponents/GuestSelector/guestSelectorHelpers';
import { formatDate } from '@/utils/globalFunc';

// import { propertyVariant } from "./enum";

// export const getMetaKey = asPath => {
//     const { hotels } = url.parse(asPath, true).query;
//     return hotels === 'true' || hotels === '1' ? propertyVariant.HOTEL : propertyVariant.VR;
// };

export function isUngrouped(service) {
    return service.name.toLowerCase() === 'ungrouped';
}

export const combineFilters = filters => Object.entries(filters)
    .reduce((acc, [filterKey, filterValue]) => ({
        ...acc,
        ...Object.entries(filterValue).reduce((acc, [key, value]) => ({
            ...acc,
            [`${filterKey}_${key}`]: value
        }), {})
    }), {});

export const getLocationTokensFromSearchTerm = searchTerm => {
    const [country, state = country, city = state] = searchTerm.split(',')
        .slice(-3)
        .map(word => word.trim())
        .reverse();

    return { city, state, country };
};

export const priceCurrencyConverter = (amount, rate = 1, contertToRate = 1) => {
    return Math.round(currencyRateConverter({
        amount,
        rate
    }, { rate: contertToRate }));
};

export const convertToHash = (filterValues = [], transformerFn) =>
    filterValues.reduce((acc, cur) => ({
        ...acc,
        [cur]: typeof transformerFn === 'function' ? transformerFn(cur) : cur
    }), {});

export const getSearchQueryParams = ({ path, params = [] }) => {
    const urlQueryParams = new URLSearchParams(path);
    return params.reduce((acc, { key, defaultValue, alias, isMultiple }) => {
        const method = isMultiple ? 'getAll' : 'get';
        let queryParam = urlQueryParams[method](key);
        const queryParamAliased = typeof alias === 'undefined' ? alias : urlQueryParams[method](alias);

        if (queryParamAliased != null) {
            queryParam = queryParamAliased;
        }

        return {
            ...acc,
            // == done purposly
            [key]: queryParam == null ? defaultValue : queryParam
        };
    }, {});
};

export function getSearchIntent(mapBounds) {
    if (Object.values(mapBounds).every(coord => !Number.isNaN(Number.parseFloat(coord)))) {
        return SearchIntendTypes.SEARCH_BY_BOUNDS;
    }
    return SearchIntendTypes.SEARCH_BY_THERM;
}

export function getCurrency(currenciesCollection, currencySearchParam) {
    for (const currency of currenciesCollection) {
        if (currency.short === currencySearchParam) {
            return currency;
        }
    }
}

export function setBrekfastIncluded(value = '') {
    return ['1', 'true'].includes(value.toLowerCase());
}

export function setCancellationPolicy(restQueryParams) {
    const filterValues = convertToHash(restQueryParams['cancellation_policy[]']);
    if (restQueryParams.cancellation_policy === recommendedWiredKeys.FREE_CANCELLATION) {
        filterValues[recommendedWiredKeys.FREE_CANCELLATION] = recommendedWiredKeys.FREE_CANCELLATION;
    }
    return filterValues;
}

export function getSpecificFiltersGroup(filterCollection, groupName) {
    return [
        ...filterCollection.reduce((accSet, { category, name }) => {
            if (category.toLocaleLowerCase() === groupName.toLocaleLowerCase()) {
                return accSet.add(name);
            }
            return accSet;
        }, new Set)
    ];
};

export function splitCollectionIntoCategories({ collection = [], lookupKey = 'category', sortByFn }) {
    return Object.entries(collection.reduce((accObject, current) => {
        const categoryName = current[lookupKey];
        if (categoryName in accObject) {
            accObject[categoryName].push(current);
        } else {
            accObject[categoryName] = [current];
        }
        return accObject;
    }, {}))
        .map(([categoryName, categoryCollection]) => {
            const collection = [...categoryCollection];
            if (sortByFn) {
                collection.sort(sortByFn);
            }

            return {
                category: categoryName,
                collection
            };
        })
        .sort((categoryWithCollectionA, categoryWithCollectionB) => {
            if (categoryWithCollectionA.category < categoryWithCollectionB.category) {
                return -1;
            }
            if (categoryWithCollectionA.category > categoryWithCollectionB.category) {
                return 1;
            }
            return 0;
        });
}

export const isDirectSearch = destinationType => [
    DestinationTypeEnum.HOTEL,
    DestinationTypeEnum.VR
].includes(destinationType);

export const getListingId = (listingId, destinationType) =>
    isDirectSearch(destinationType)
        ? listingId
        : undefined;

export const isExpandedMap = vState => [viewState.DESKTOP.MAP_EXPANDED].includes(vState);

export const childAgesAdapter = (
    { guests = [], childrenAges = [] },
    guestAgeTypeCollection = []
) => {
    const childAgesFromGuestsProvider = () => guests.reduce((childAges, { guestAgeType, age }) => {
        if ([guestAgeTypes.CHILD, ...guestAgeTypeCollection].includes(guestAgeType)) {
            return [
                ...childAges,
                age
            ];
        }
        return childAges;
    }, []);

    const childAgesProvider = () => childrenAges.filter(age => String(age).length > 0);

    for (const provider of [childAgesProvider, childAgesFromGuestsProvider]) {
        const childAges = provider();

        if (Array.isArray(childAges) && (childAges.length > 0)) {
            return childAges;
        }
    }

    return [];
};

export const getDeviceResolutionZoomLevel = ({
    resolutionType: resType,
    searchedLocation: { zoomLevel, mobileZoomLevel }
}) => {
    if (mobileZoomLevel) {
        return [resolutionType.MOBILE].includes(resType)
            ? mobileZoomLevel
            : zoomLevel;
    }

    return zoomLevel;
};

export const getValidLocationType = (payloadLocationType, stateLocationType) => {
    return Object.values(LocationTypes).includes(payloadLocationType)
        ? payloadLocationType
        : stateLocationType;
};

export const getPrimaryFilterValues = ({ dateFormat } = {}) => ({
    dates: { startDate, endDate },
    rooms,
    guests
}) => ({
    checkin: formatDate(startDate, dateFormat),
    checkout: formatDate(endDate, dateFormat),
    rooms,
    adults: getCountByGuestType(guests, guestAgeTypes.ADULT),
    numberOfChildrenBelow2: getCountByGuestType(guests, guestAgeTypes.INFANT),
    numberOfChildrenBelow17: getCountByGuestType(guests, guestAgeTypes.CHILD),
    child_ages: childAgesAdapter({
        guests
    }),
});
