import { memo, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";

import { PermStatusState, useGeolocation } from "@/src/utils/customHooks/useGeolocation";
import { UserLocationDialog } from "../UserLocationDialog";
import { updateUserLocation } from "./utils";


function UserLocationCheck() {
    const router = useRouter();
    const { getPermission } = useGeolocation();
    const [permission, setPermission] = useState(null);

    const showDialog = useMemo(() => ['/'].includes(router.asPath), [router.asPath]);

    useEffect(() => {
        getPermission()
            .then((permStatus) => {
                setPermission(permStatus?.state);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [getPermission, setPermission]);

    useEffect(() => {
        if (!permission) {
            return;
        }

        const actions = {
            [PermStatusState.GRANTED]: () => updateUserLocation({ isAllowed: true }),
            [PermStatusState.DENIED]: () => updateUserLocation({ isAllowed: false }),
            [PermStatusState.PROMPT]: () => {
                if (showDialog) return;
                updateUserLocation({ isAllowed: false });
            },
        };

        actions[permission]();
    }, [showDialog, permission]);

    return (
        (showDialog && permission === PermStatusState.PROMPT)
            ? <UserLocationDialog />
            : null
    );
}

export default memo(UserLocationCheck);
